html, body {
  height: 100vh;
  margin: 0;
}

body {
  color: #fff;
  background-color: #121212;
  font-family: Segoe UI, Verdana, sans-serif;
}

#FeAARG_view {
  height: 100%;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
}

#FeAARG_nav {
  z-index: 2;
  color: #f8de08;
  background-color: #424242;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  line-height: 50px;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
}

#FeAARG_userToggle {
  display: none;
}

.FeAARG_labelToggleNav {
  cursor: pointer;
  border-radius: 8px;
  transition: all .25s ease-out;
  display: block;
}

.FeAARG_labelToggleNav:hover {
  color: #ea1c2d;
}

.FeAARG_labelToggleNav:before {
  content: " ";
  vertical-align: middle;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid;
  margin-right: .7rem;
  transition: transform .2s ease-out;
  display: inline-block;
  transform: translateY(-2px);
}

#FeAARG_userToggle:checked + .FeAARG_labelToggleNav:before {
  transform: rotate(90deg)translateX(-3px);
}

#FeAARG_navigation {
  float: left;
  background-color: #424242;
  align-items: center;
  column-gap: 40px;
  min-height: 50px;
  display: flex;
  position: relative;
}

.FeAARG_Link {
  color: #f8de08;
  border-top: 0;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.FeAARG_Link:has(img) {
  line-height: 0;
}

#FeAARG_logo {
  vertical-align: text-bottom;
  width: 150px;
}

.FeAARG_Link:hover {
  color: #ea1c2d;
}

#FeAARG_userDropdown {
  float: right;
  background-color: #424242;
  min-width: 160px;
  min-height: 50px;
  position: relative;
}

#FeAARG_username {
  border-top: 0;
  padding: 10px 20px;
  font-size: 20px;
  text-decoration: none;
}

#FeAARG_menu {
  z-index: 100;
  background-color: #212121;
  width: calc(100% - 40px);
  padding: 0 20px;
  display: none;
  position: absolute;
}

#FeAARG_menu > a {
  color: #f8de08;
  text-decoration: none;
  display: block;
}

#FeAARG_menu > a:hover {
  color: #ea1c2d;
}

.FeAARG_num_stats {
  font-size: 50px;
}

.FeAARG_big_button {
  color: #fff;
  background-color: #ea1c2d;
  border: none;
  border-radius: 20px;
  width: 300px;
  height: 60px;
  font-size: 20px;
}

.FeAARG_big_button:hover, .FeAARG_small_button:hover {
  cursor: pointer;
  background-color: red;
}

.FeAARG_small_button {
  color: #fff;
  background-color: #ea1c2d;
  border: none;
  border-radius: 20px;
  width: 150px;
  height: 30px;
  font-size: 13px;
}

.FeAARG_cancel_button, .FeAARG_confirm_button {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

.FeAARG_cancel_button {
  background-color: #f8de08;
}

.FeAARG_cancel_button:hover {
  background-color: #ff0;
}

.FeAARG_confirm_button {
  color: #fff;
  background-color: #ea1c2d;
}

.FeAARG_confirm_button:hover {
  background-color: red;
}

.FeAARG_idle_circle {
  color: #ff0;
  margin-right: 10px;
}

.FeAARG_online_circle {
  color: green;
  margin-right: 10px;
}

.FeAARG_offline_circle {
  color: #353535;
  margin-right: 10px;
}

.FeAARG_connection_holder {
  align-content: center;
  display: flex;
}

.FeAARG_page_header_holder {
  justify-content: space-between;
  display: flex;
}

.FeAARG_page_container {
  margin: 40px 80px;
  padding-bottom: 50px;
}

.FeAARG_notify {
  color: #0000;
  margin-bottom: 10px;
}

.FeAARG_error {
  color: #ff8282;
  margin-bottom: 10px;
  font-weight: 500;
}

.FeAARG_success {
  color: green;
  margin-bottom: 10px;
}

.FeAARG_waiting {
  color: #f8de08;
  margin-bottom: 10px;
}

.FeAARG_hide_big_button {
  color: #0000;
  background-color: #0000;
  border: none;
  border-radius: 20px;
  width: 300px;
  height: 60px;
  font-size: 20px;
}

#FeAARG_logout {
  cursor: pointer;
}

#FeAARG_logout:hover, #FeAARG_email_link {
  color: #ea1c2d;
}

#FeAARG_privacy_list > li {
  color: #f8de08;
}

#FeAARG_privacy_list > li > p {
  color: #fff;
}

.y6WAcq_holder {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  padding-top: 0;
  display: flex;
}

.y6WAcq_holder > div {
  background-color: #424242;
  border-radius: 20px;
  min-width: 250px;
  max-width: 460px;
  padding: 60px 80px;
}

.y6WAcq_form {
  background-color: #424242;
  display: block;
}

.y6WAcq_form > a {
  color: #f8de08;
}

.y6WAcq_form > input[type="text"], .y6WAcq_form > input[type="password"], .y6WAcq_form > input[type="email"] {
  border-radius: 10px;
  width: calc(100% - 16px);
  margin-bottom: 5px;
  padding-left: 10px;
  display: block;
}

.y6WAcq_big_form_confirmation {
  color: #fff;
  background-color: #ea1c2d;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 60px;
  margin-top: 65px;
  margin-bottom: 20px;
  font-size: 20px;
}

.y6WAcq_big_form_confirmation:hover {
  background-color: red;
}

h1 {
  color: #f8de08;
}

#y6WAcq_disabled_email {
  color: #fff;
}

.y6WAcq_buttons_holder {
  display: flex;
}

#vPyWoa_stats_holder {
  place-content: stretch space-between;
  margin: 50px 0;
  display: flex;
}

.vPyWoa_stats {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  width: 30%;
  padding-bottom: 30px;
  position: relative;
}

.vPyWoa_stats > h2, .vPyWoa_stats > a, .vPyWoa_stats > div {
  padding-left: 20px;
}

.vPyWoa_stats > a {
  color: #c1c1c1;
  text-decoration: none;
  position: absolute;
  bottom: 10px;
}

#vPyWoa_buttons_holder {
  justify-content: space-around;
  margin: 50px 0;
  display: flex;
}

.vPyWoa_element_holder {
  margin-bottom: 20px;
  display: block;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

._uFXmG_popup_holder {
  z-index: 9999;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

._uFXmG_popup_content {
  background-color: #424242;
  border-radius: 20px;
  padding: 60px 80px;
}

form > input[type="text"], form > input[type="password"], form > input[type="email"] {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 10px;
  display: block;
}

._uFXmG_back_arrow {
  cursor: pointer;
  margin-right: 20px;
  font-size: 36px;
}

h1, h2, h3, h4 {
  color: #f8de08;
}

._uFXmG_search_bar {
  width: 400px;
}

#_uFXmG_large_confirm {
  width: 100%;
  margin-top: 30px;
}

._uFXmG_header {
  align-items: center;
  display: flex;
}

._uFXmG_buttons_holder {
  display: flex;
}

#_uFXmG_manage_access {
  margin-right: 10px;
}

._uFXmG_confirm_message {
  margin-bottom: 20px;
}

#_07hD-q_holder {
  background-color: #424242;
  border-radius: 20px;
  padding: 60px;
}

#_07hD-q_options_div {
  background-color: #424242;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
}

#_07hD-q_options_div > fieldset > input[type="radio"] {
  display: none;
}

#_07hD-q_options_div > fieldset {
  border: none;
  border-radius: 10px;
  justify-content: space-around;
  display: flex;
}

#_07hD-q_options_div > fieldset > label {
  text-align: center;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  flex: 1;
  padding: 6px;
}

#_07hD-q_options_div > fieldset > input[type="radio"]:checked + label {
  color: #000;
  background-color: #ff0;
}

#_07hD-q_header {
  align-items: center;
  display: flex;
}

.kbAyjW_car_component > input {
  display: none;
}

.kbAyjW_car_component {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.kbAyjW_car_component > label {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  padding: 10px;
  display: block;
}

.kbAyjW_car_component > input:checked + label {
  color: #000;
  background-color: #f8de08;
}

#_043La_players_list {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  margin-top: 30px;
  padding-left: 10px;
}

#_043La_players_list > ul {
  list-style-type: none;
}

#_043La_wait {
  color: #0000;
  font-size: 13px;
}

#_043La_button {
  color: #0000;
  background-color: #0000;
}

#_043La_current_player {
  color: #ea1c2d;
}

table {
  table-layout: fixed;
  width: 100%;
  padding-top: 30px;
  overflow-y: scroll;
}

thead > tr > td {
  color: #f8de08;
}

label {
  margin-right: 10px;
}

input[type="text"], select {
  border-radius: 10px;
  padding-left: 10px;
}

input[type="text"] {
  width: 400px;
}

#n02mWa_ranking_settings {
  padding-top: 15px;
}

.ES3S3W_form_select {
  width: 100%;
}

#PJwTWG_grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

#PJwTWG_game {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  display: flex;
}

#PJwTWG_players {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  grid-area: 1 / 1 / 3 / 2;
  padding: 10px;
}

h5 {
  font-size: 20px;
}

#PJwTWG_current_player {
  color: #ea1c2d;
}

.PJwTWG_health_score {
  align-items: center;
  display: flex;
}

#PJwTWG_game_state {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  grid-area: 1 / 2 / 2 / 3;
  padding: 10px;
}

.PJwTWG_state {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#PJwTWG_rules {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  grid-area: 2 / 2 / 3 / 3;
  padding: 10px;
}

.PJwTWG_progress_bar_holder {
  align-items: center;
  display: flex;
}

.PJwTWG_progress_bar {
  background-color: #bdbdbd;
  border-radius: 5px;
  min-width: 200px;
  max-width: 500px;
  height: 30px;
  margin-left: 60px;
  margin-right: 10px;
}

.PJwTWG_progress {
  background-color: #54bf00;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

/*# sourceMappingURL=index.77c63d9a.css.map */
